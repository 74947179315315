import * as React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import glamorous from 'glamorous';
import { RvLoader } from '../components/Loader';
import { Button, Grid } from '@material-ui/core';
import { RvLabel, LabelStyle } from '../components/Label/Label';
import { MatterTerminology } from '../MatterTerminology';
import { browserHistory } from '../components/AppContent';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import DeveloperModeOutlinedIcon from '@material-ui/icons/DeveloperModeOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import // faChartLine,
// faKey,
// faUserPlus,
// faUnlockAlt
'@fortawesome/free-solid-svg-icons';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CommonMaterialCard from './CommonMaterialCard';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { RoleConfigurationCard } from './RoleConfigurationCard';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import MailIcon from '@material-ui/icons/Mail';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { LearnMoreEnum } from '../learnMore/LearnMorePage';
import { RouterProps } from '../App';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { TypeOfDashboard } from '../featureConfigurationLayout/FeatureLayoutConfigurationPage';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TextFieldsIcon from '@material-ui/icons/TextFields';

// tslint:disable-next-line:no-any
const Wrapper = glamorous.div<{ theme?: any }>((props) => ({
    padding: 15,
    '& .custom-grid': {},
}));

// tslint:disable-next-line:no-any
const Header = glamorous.div<{ theme?: any }>((props) => ({
    position: 'relative',
    padding: '15px 80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: props.theme.BrandColors.TextPrimary,
    '@media (max-width: 1024px)': {
        padding: '15px 30px',
    },
    [`& .${`rvlabel`}`]: {
        // paddingLeft: '82px',
        fontFamily: props.theme.Font.Main,
        fontSize: props.theme.FontSize.PX.size8,
        letterSpacing: '0.86px',
        lineHeight: props.theme.LineHeight.ExtraLarge,
    },
    [`& .${`back-button`}`]: {
        paddingRight: '32px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [`& .${`ms-Button`}`]: {
            width: '50px',
            height: '50px',
        },
        [`& .${`ms-Button-icon`}`]: {
            fontSize: props.theme.FontSize.PX.size10,
            color: props.theme.BrandColors.TemplatePrimary,
        },
    },
}));

const ButtonGroupWrapper = glamorous.div<{ theme?: object }>((props) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '0 -10px',
    '& > .learnmore': {
        margin: '0 10px',
        textTransform: 'none !important',
        fontSize: '14px',
    },
}));

export enum ListOfConfigEnum {
    lookupConfiguration = 'lookupConfiguration',
    firmStructureConfiguration = 'firmStructureConfiguration',
    helpConfiguration = 'helpConfiguration',
    brandConfiguration = 'brandConfiguration',
    userConfiguration = 'userConfiguration',
    integrationConfiguration = 'integrationConfiguration',
    firmSettingConfiguration = 'firmSettingConfiguration',
    applicationSettings = 'applicationSettings',
    emailConfiguration = 'emailConfiguration',
    splashScreenConfiguration = 'splashScreenConfiguration',
    featureAccessConfiguration = 'featureAccessConfiguration',
    featureLicenceConfiguration = 'featureLicenceConfiguration',
    generalMaterialConfiguration = 'generalMaterialConfiguration',
    roleSecurityConfiguration = 'roleSecurityConfiguration',
    matterViewColumnSecurityConfiguration = 'matterViewColumnSecurityConfiguration',
    enquiryCustomFieldsConfiguration = 'enquiryCustomFieldsConfiguration',
}

interface ExternalProps {}

interface Data {
    loading?: boolean;
    networkStatus?: number;
    // tslint:disable-next-line: no-any
    appUser: any;
}

interface ConfigurationDashboardProps extends ExternalProps, RouterProps {
    data: Data;
}

// tslint:disable-next-line:no-any
class ConfigurationDashboard extends React.Component<ConfigurationDashboardProps, {}> {
    constructor(props: ConfigurationDashboardProps) {
        super(props);
        this.onClickBackBtn = this.onClickBackBtn.bind(this);
        // this.onClickFirmDasboard = this.onClickFirmDasboard.bind(this);
        // this.onClickLeadDasboard = this.onClickLeadDasboard.bind(this);
        // this.onClickTaskDasboard = this.onClickTaskDasboard.bind(this);
        this.onLearnMore = this.onLearnMore.bind(this);
        this.onClickDashboard = this.onClickDashboard.bind(this);
        this.onNavigateToConfig = this.onNavigateToConfig.bind(this);
        this.onDocument = this.onDocument.bind(this);
    }

    render() {
        const terminology = { ...MatterTerminology };

        if (this.props.data.loading && this.props.data) {
            return <RvLoader size="small" />;
        }
        return (
            <Wrapper>
                <div className="container-userlist">
                    <Header className="summaryheaderarea">
                        {/* <RvButtonFabric
                            rvKey="close"
                            rvbuttonType={RvButtonType.icon}
                            onClick={this.onClickBackBtn}
                            rvIcon={'NavigateForwardMirrored'}
                            rvClassName="back-button"
                        /> */}
                        <RvLabel label={terminology.Configuration} style={LabelStyle.Heading4} />
                        <ButtonGroupWrapper>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                className="learnmore"
                                onClick={() => this.onLearnMore(LearnMoreEnum.System_Configuration_Overview)}
                                startIcon={<VideoLibraryOutlinedIcon />}
                            >
                                Learn More
                            </Button>
                            {/* <Button 
                                variant="text" 
                                color="primary"
                                size="small"
                                className="learnmore"
                                onClick={this.onResources}
                                startIcon={<ErrorOutlineIcon />}
                            >
                                Resources
                            </Button> */}
                        </ButtonGroupWrapper>
                    </Header>
                </div>
                <Grid container={true} spacing={1}>
                    <Grid item={true} sm={12} xs={12} md={12}>
                        <Grid container={true} spacing={1}>
                            {(UserFeature.HasAccess(UserFeatureEnum.hasFirmSettingsConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<SettingsOutlinedIcon />}
                                        title="Firm Settings"
                                        description="Configure settings and defaults for the Firm"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.firmSettingConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasFeatureAccessConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<LockOpenIcon />}
                                        title="Feature Access"
                                        description="Configure access to application features"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.featureAccessConfiguration)}
                                        onDocument={() => this.onDocument(ListOfConfigEnum.featureAccessConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasRoleConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <RoleConfigurationCard />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasLookupConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<LibraryAddCheckIcon />}
                                        title="Lookup Lists"
                                        description="Maintain various reference lookup information"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.lookupConfiguration)}
                                        onLearnMore={() => this.onLearnMore(LearnMoreEnum.LOOKUP_LISTS)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasEmailTemplateConfigurationPage) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<MailIcon />}
                                        title="Email Templates"
                                        description="Configuration of emails generated by the system"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.emailConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasEnquiryCustomFieldsConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TextFieldsIcon />}
                                        title="Enquiry Custom Fields"
                                        description="Maintain custom fields and groupings for data capture against Enquiries."
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.enquiryCustomFieldsConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasFirmStructure) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<AccountTreeIcon />}
                                        title="Firm Structure"
                                        description="Maintain information relating to staff groups and structures"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.firmStructureConfiguration)}
                                        onLearnMore={() => this.onLearnMore(LearnMoreEnum.FIRM_STRUCTURE)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboardConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TrendingUpIcon />}
                                        title="Firm Dashboard"
                                        description="Configure the screen elements to be displayed in firm dashboards"
                                        onClick={() => this.onClickDashboard(TypeOfDashboard.firm)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasFeeEarnerDashboardConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TrendingUpIcon />}
                                        title={MatterTerminology.FeeEarnerDashboard}
                                        description="Configure the screen elements to be displayed in fee earner dashboard"
                                        onClick={() => this.onClickDashboard(TypeOfDashboard.feeEarner)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasLeadsDashboardConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TrendingUpIcon />}
                                        title="Enquiry Dashboard"
                                        description="Configure the screen elements to be displayed in enquiry dashboard"
                                        onClick={() => this.onClickDashboard(TypeOfDashboard.lead)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasTaskDashboardConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TrendingUpIcon />}
                                        title="Task Dashboard"
                                        description="Configure the screen elements to be displayed in task dashboard"
                                        onClick={() => this.onClickDashboard(TypeOfDashboard.task)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasClientDashboardConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<TrendingUpIcon />}
                                        title="Client Dashboard"
                                        description="Configure the screen elements to be displayed in client dashboard"
                                        onClick={() => this.onClickDashboard(TypeOfDashboard.client)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasSplashScreenConfigurationPage) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<DeveloperModeOutlinedIcon />}
                                        title="Splash Templates"
                                        description="Configuration of Splash Screens to appear at user login"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.splashScreenConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasUserDefinedHelpConfigurationPage) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<HelpOutlineIcon />}
                                        title="User Defined Help"
                                        description="Configuration of user defined help"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.helpConfiguration)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasBrandsConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard 
                                        icon={<AppsOutlinedIcon />} 
                                        title="Brands" 
                                        description="Configuration of brand" 
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.brandConfiguration)} 
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasIntegrationConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<SettingsInputSvideoIcon />}
                                        title="Integrations"
                                        description="Configuration of system integrations with third parties"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.integrationConfiguration)}
                                    />
                                </Grid>
                            )}
                            {(UserFeature.HasAccess(UserFeatureEnum.hasFeatureLicencingConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<BusinessCenterOutlinedIcon />}
                                        title="Knowledge Bank"
                                        description="Configuration of knowledge bank"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.generalMaterialConfiguration)}
                                    />
                                </Grid>
                            )}
                            {(UserFeature.HasAccess(UserFeatureEnum.hasRoleSecurityConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<AccessibilityNewOutlinedIcon />}
                                        title={MatterTerminology.RoleSecurity}
                                        description="Configure the matter information that different roles are allowed to access"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.roleSecurityConfiguration)}
                                    />
                                </Grid>
                            )}
                            {(UserFeature.HasAccess(UserFeatureEnum.hasSummaryColumnSecurity)) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<VisibilityOutlinedIcon />}
                                        title="Matter View Column Security"
                                        description="Configure the columns in the matter summary view that users are allowed to access"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.matterViewColumnSecurityConfiguration)}
                                    />
                                </Grid>
                            )}
                            {this.props.data.appUser.isInternalAdmin && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<SettingsOutlinedIcon />}
                                        title="Application Settings"
                                        description="Configure internal settings used by the application"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.applicationSettings)}
                                    />
                                </Grid>
                            )}

                            {(UserFeature.HasAccess(UserFeatureEnum.hasFeatureLicencingConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid">
                                    <CommonMaterialCard
                                        icon={<VpnKeyIcon />}
                                        title="Feature Licensing"
                                        description="Licence use of application features"
                                        onClick={this.onNavigateToConfig(ListOfConfigEnum.featureLicenceConfiguration)}
                                    />
                                </Grid>
                            )}
                            {/* TODO: Commenting this as per Stephen's request.   */}
                            {/* { (UserFeature.HasAccess(UserFeatureEnum.hasUserOptionsConfiguration) || this.props.data.appUser.isInternalAdmin) && (
                                <Grid item={true} sm={6} xs={12} md={4} lg={3} className="custom-grid" >
                                <CommonMaterialCard
                                    icon={<SettingsInputCompositeOutlinedIcon />}
                                    title="User Options"
                                    description="Configure options and default options for users"
                                    onClick={this.onNavigateToConfig(ListOfConfigEnum.userConfiguration)}
                                />
                                </Grid>
                            )} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        );
    }

    private onClickBackBtn() {
        // console.log
    }

    private onClickDashboard(dashboardType: TypeOfDashboard) {
        sessionStorage.setItem('personalise', '');
        let layoutId = 0;
        switch (dashboardType) {
            case TypeOfDashboard.firm:
                layoutId = 0;
                break;
            case TypeOfDashboard.lead:
                layoutId = 1;
                break;
            case TypeOfDashboard.feeEarner:
                layoutId = 2;
                break;
            case TypeOfDashboard.task:
                layoutId = 3;
                break;
            case TypeOfDashboard.client:
                layoutId = 4;
                break;
            default:
                layoutId = 0;
                break;
        }

        browserHistory.push({
            pathname: '/dashboardLayoutConfiguration',
            state: {
                layoutId: layoutId,
            },
        });
    }

    private onNavigateToConfig = (navigate: ListOfConfigEnum) => () => {
        browserHistory.push({
            pathname: `/${ListOfConfigEnum[navigate]}`,
        });
    }

    private onLearnMore(type: LearnMoreEnum) {
        this.props.history.push({
            pathname: `/learnMore/${LearnMoreEnum[type]}`,
        });
    }

    private onDocument(navigate: ListOfConfigEnum) {
        let link = '';
        
        if (navigate === ListOfConfigEnum.featureAccessConfiguration) {
            link = 'https://redraincorp.sharepoint.com/:b:/s/RedViewProduct/EZ1FKuPeYgZGuCcOGwV2tmIB4mGdOmIIPSP0gRWryuy_NA?e=yQgMJ4';
        }

        window.open(link, '_blank');
    }
}

export const UserData = gql`
    query UserData {
        appUser {
            isInternalAdmin
        }
    }
`;

// tslint:disable-next-line:no-any
export const ConfigurationDashboardPage = graphql<any, ExternalProps, any>(UserData, {
    options: ({ nameId }) => ({ variables: {} }),
})(ConfigurationDashboard);
